<template>
    <div class="aipage">
        <div class="banner-img background" :style="{ 'backgroundImage' : `url(${require('@/assets/images/case/bannerai.png')})`}">
            <div class="banner">
                <div class="banner-title background" :style="{ 'backgroundImage' : `url(${require('@/assets/images/case/caseTitle2.png')})`}"></div>
                <div class="banner-smallTitle">人工智能技术研究和应用已上升为国家重大发展战略，相关专业建设和人才培养已经成为高校的新课题。</div>
            </div>
        </div>
        <div class="aipage_wrap">
            <div class="aipage_content">
                <p class="titleBlue">客户案例</p>
                <div class="flex-between">
                    <div style="width: calc(100% - 5.5rem);">
                        <p :style="{ 'backgroundImage' : `url(${require('@/assets/images/case/titlebg.png')})`,'background-size':'cover','font-size':'0.16rem', 'width':'3.6rem', 'height':'0.42rem','line-height':'0.42rem','color':'#fff','font-weight':'600','text-indent': '0.12rem','margin-bottom':'0.2rem','font-family':'黑体'}">西交利物浦大学“AI实训课”</p>
                        <p class="titleBlueSmall">客户介绍</p>
                        <div class="case-content" style="margin-bottom: 0.4rem;">
                            西交利物浦大学（XJTLU，简称“西浦”）是经中国教育部批准，由西安交通大学和英国利物浦大学合作创立的，具有独立法人资格和鲜明特色的新型国际大学。她是中国目前规模最大的中外合作大学，以理工管起步，强强合作，拥有中华人民共和国学士学位和英国利物浦大学学位授予权。
                        </div>
                        <p class="titleBlueSmall">案例介绍</p>
                        <div class="case-content">
                            2021年7月暑假前后，中科逆熵委派多名高级研发讲师来到西交利物浦大学苏州校区，面向近百名高校学生，开展了以《面向电商大数据的人工智能搜索推荐系统的研究与实现》为主题的实验课程。
                        </div>
                    </div>
                    <div class="background" :style="{ 'backgroundImage' : `url(${require('@/assets/images/case/lwp.png')})`, 'width':'4.7rem', 'height':'3.2rem', 'background-size':'cover'}"></div>
                </div>
            </div>
        </div>
        <div class="aipage_wrap" style="padding-bottom: 0.4rem;">
            <div class="aipage_content">
                <div class="flex-between">
                    <div class="case-img flex-center" v-for="item in caseList" :key="item.index">
                        <div class="background" :style="{ 'backgroundImage' : `url(${ item })`, 'width':'2.7rem', 'height':'2rem', 'background-size':'cover'}"></div>
                    </div>
                </div>
                <div class="case-content" style="margin-top: 0.3rem;">
                    此次实验课程以权威电商平台大数据为背景，引导学生们在国产智能计算云平台上，开发实现基于大数据的商用智能推荐系统。为体现实用性、开放性和研究性，本次实验主要基于亚马逊开源数据集完成。在数据挖掘领域，AI+大数据处理是大势所趋，
                    所以此次实验选用了国产智能计算云平台——南京智能计算中心作为硬件平台。该平台具有国产化、高能效、可扩展、易使用等特性。另外，其所具有的极好的延续性可以将暑期课程价值最大化，即使在课程结束后，同学们仍然可以继续使用此平台开展学习研究工作。
                    不仅如此，中科逆熵同时提供了一整套包含软件、硬件、教材以及技术服务，帮助学生更快展开本次实验训练。
                </div>
            </div>
        </div>
        <div class="aipage_wrap" >
            <div class="aipage_content flex-between" :style="{ 'backgroundImage' : `url(${require('@/assets/images/case/casebg3.png')})`, 'width':'12rem','height':'3.8rem','background-size':'cover', 'margin-bottom': '0.3rem'}">
                <div class="content-img" :style="{ 'backgroundImage' : `url(${require('@/assets/images/case/firend.png')})`}"></div>
                <div class="content-content">
                    <p>目前南京中科逆熵科技有限公司已面向国内多所院校合作展开智慧教育深化合作。通过多种教研模式，例如：实验基地，产研合作，校外竞赛、实习培训等等，并为他们提供了设备、课件、在线课程等资源，形成长期与高校学院长期发展的智慧教学生态训练营，
                        引导学生在真实的环境中了解行业背景，深度开发学生的在大数据，人工智能开发过程中的实践能力。
                    </p>
                    <p>大数据、人工智能已逐步上升为国家重大发展战略，为此中科逆熵将继续加强和各大院校的深度合作，为推动我国人工智能教学及专精人才的培养做出更大的贡献。</p>
                </div>
            </div>
        </div>
    </div>
  </template>
  <script>
  /* eslint-disable */
  export default {
    data() {
      return {
        caseList:[require('@/assets/images/case/caseai1.png'),require('@/assets/images/case/caseai2.png'),require('@/assets/images/case/caseai3.png'),require('@/assets/images/case/caseai4.png')]
      }
    },
   
    mounted() {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        this.plateFormInfo = this.plateFormFunction[0]
    },
    methods: {
        
    },
  }
    </script>
<style lang="scss" scoped>
.banner {
    left: 20%;
    top:1.4rem;
    &-title{
        width: 4.7rem;
        height: 1.4rem;
        background-size: contain;
    }
    &-smallTitle{
        color: #fff;
        width: 6.8rem;
        letter-spacing: 2px;
        line-height: 0.24rem;
        font-size: 0.14rem;
    }
}
.background{
    background-position: center;
    background-repeat: no-repeat;
}
.banner-img{
    width: 100%;
    height: 2.8rem;
    position: relative;
    background-size: cover;
}
.titleBlue{
    font-size: 0.3rem;
    color: #0061E3;
    font-weight: 600;
    letter-spacing: 0.08rem;
    text-align: center;
    height: 0.6rem;
    margin: 0.2rem 0;
    font-family: '黑体';
}
.titleBlueSmall{
    font-size: 0.16rem;
    height: 0.3rem;
    margin: 0 0.12rem;
    color: #0061E3;
    font-weight: 600;
    font-family: '黑体';
}
.whiteBlue{
    width: 100%;
    font-size: 0.28rem;
    color: #fff;
    font-weight: 600;
    letter-spacing: 0.08rem;
    text-align: center;
    height: 0.6rem;
}
.plateInfo-left{
    width: 2rem;
    height: 4rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.box-left{
    width: 3rem;
}
.box-right{
    width: calc(100% - 4rem);
}
.box-blue-title{
    width: 100%;
    height: 0.32rem;
    line-height: 0.32rem;
    background-color: #0061E3;
    color: #fff;
    font-size: 0.18rem;
    font-weight: 600;
    text-align: center;
    border-radius: 0.04rem;
}
.box-middle-label{
    height: 0.6rem;
    line-height: 0.6rem;
    color: #fff;
}
.case-content{
    font-size: 0.14rem;
    color: #333;
    text-indent: 0.28rem;
    line-height: 0.28rem;
}
.case-box{
    width: 3.4rem;
    height: 1.6rem;
    padding: 0.4rem 0.2rem;
    border: 1px solid #cfd3da;
    flex-direction: column;
    text-align: center;
    img{
        margin: 0 auto;
    }
}
.case_content{
    text-indent: 0.28rem;
    font-size: 0.14rem;
    letter-spacing: 0.02rem;
    line-height: 0.24rem;
}
.content-img{
    width: 5.4rem;
    height: 3.2rem;
    position:relative;
    top:0.3rem;
    left:0.2rem;
    background-size: contain;
    background-position:center;
    background-repeat:no-repeat;
}
.content-content{
    width: calc(100% - 6.5rem);
    text-indent: 0.28rem;
    color: #fff;
    line-height: 0.28rem;
    letter-spacing: 0.01rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right:0.3rem;
}
</style>
<style lang="scss">
.aipage{
    .el-button--small{
        padding:0.1rem 0.4rem;
        border-color: #0061e3;
        color: #0061e3;
        font-weight: 600;
        border-radius: 0;
    }
    .el-button--small:hover{
        background-color: #0061e3;
        color: #fff;
    }
}
.aipage_wrap{
    width: calc(100% - 0.48rem);
    display: flex;
    justify-content: center;
    padding: 0.24rem;
    background-color: #f5f5f5;
}
.aipage_content{
    width: 12rem;
}
.flex-center{
    display: flex;
    justify-content: center;
}
.flex-wrap{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}
.flex-between{
    display: flex;
    justify-content: space-between;
}
.dot-w{
    display: inline-block;
    width: 0.08rem;
    height: 0.08rem;
    background-color: #fff;
    border-radius: 50%;
}
.el-divider--horizontal{
    margin: 0.16rem 0;
}

</style>
 